<template>
  <v-card class="mx-auto my-0 pb-0">
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel key="0">
        <v-expansion-panel-header>
          {{ $t("syncConfiguration") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col :cols="$vuetify.breakpoint.width < 750 ? '12' : '5'">
              <tool-set-uper
                :currentPossibleTools="currentPossibleToolsForFirst"
                :connectionBoxId="connectionBoxId"
                :numberOfTool="'first'"
                :project2project="project2project"
                @chosen-tool="changeToolsForSecond"
                @tool-configured="firstToolConfigured"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width < 750 ? '12' : '2'">
              <h4 style="text-align: center" class="mt-n2">
                <br />
                &lt;&lt; &gt;&gt;
              </h4>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width < 750 ? '12' : '5'">
              <tool-set-uper
                :currentPossibleTools="currentPossibleToolsForSecond"
                :connectionBoxId="connectionBoxId"
                :numberOfTool="'second'"
                :project2project="project2project"
                @chosen-tool="changeToolsForFirst"
                @tool-configured="secondToolConfigured"
              />
            </v-col>
          </v-row>
          <v-row><p></p></v-row>
          <v-row>
            <project-pairing
              v-if="project2project"
              :connectionBox="connectionBox"
              :toolsConfigured="isFirstToolConfigured && isSecondToolConfigured"
              @pairs-configured="projectPairsConfigured"
            >
            </project-pairing>
          </v-row>
          <v-row justify="center" class="mb-n2">
            <v-tooltip bottom :disabled="isConfigured">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    elevation="2"
                    class="mt-2"
                    color="success"
                    @click="
                      loader = 'saveRun';
                      saveClicked();
                    "
                    :loading="isLoading"
                    :disabled="!isConfigured"
                  >
                    {{ $t("buttons.save") }}
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </div>
              </template>
              <span v-if="!isConfigured">
                {{ $t("toolTips.missingToolsInfo") }}
              </span>
            </v-tooltip>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar right top app color="success" v-model="saveProcessed">
      {{ $t("notificationSnackbars.syncConfiguration") }}
      {{ connectionBoxId }}
      {{ $t("notificationSnackbars.saved") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="saveProcessed = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="success" v-model="saveUpdated">
      {{ $t("notificationSnackbars.syncConfiguration") }}
      {{ connectionBoxId }}
      {{ $t("notificationSnackbars.savedUpdated") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="saveUpdated = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="saveError">
      {{ $t("notificationSnackbars.syncConfiguration") }}
      {{ connectionBoxId }}
      {{ $t("notificationSnackbars.notSuccessful") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="saveError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { ToolType } from "@/enums/tools/typeOfTool";
import { EventBus } from "@/EventBus";
import {
  createConnection,
  updateConnection,
} from "../../../services/backendService";
import { mapConnectionToConnectionBox } from "../shared";
import ConnectionBoxVue from "../ConnectionBox.vue";

export default {
  components: {
    "tool-set-uper": () => import("./ToolSetUper.vue"),
    "project-pairing": () => import("./ProjectPairing.vue"),
  },
  data() {
    return {
      panel: [],
      currentPossibleToolsForFirst: [],
      currentPossibleToolsForSecond: [],

      isFirstToolConfigured: false,
      isSecondToolConfigured: false,
      areProjectPairsConfigured: false,

      saveProcessed: false,
      saveUpdated: false,
      saveError: false,
      loader: null,
      saveRun: false,
      isLoading: false,
    };
  },
  created() {
    this.fetchDefaultPossibleTools();
  },
  computed: {
    isConfigured() {
      return (
        this.isFirstToolConfigured &&
        this.isSecondToolConfigured &&
        (this.project2project ? this.areProjectPairsConfigured : true)
      );
    },
    project2project() {
      this.currentPossibleToolsForFirst;
      this.currentPossibleToolsForSecond;
      return (
        (this.connectionBox.firstTool.tool === "Jira" &&
          this.connectionBox.secondTool.tool === "Redmine") ||
        (this.connectionBox.firstTool.tool === "Redmine" &&
          this.connectionBox.secondTool.tool === "Jira") ||
        (this.connectionBox.firstTool.tool === "Jira" &&
          this.connectionBox.secondTool.tool === "Jira") ||
        (this.connectionBox.firstTool.tool === "Redmine" &&
          this.connectionBox.secondTool.tool === "Redmine")
      );
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    firstToolConfigured(state) {
      this.isFirstToolConfigured = state;
    },

    secondToolConfigured(state) {
      this.isSecondToolConfigured = state;
    },
    projectPairsConfigured(state) {
      this.areProjectPairsConfigured = state;
    },

    // fetch possible tools for first and second tool
    fetchDefaultPossibleTools() {
      this.fetchDefaultPossibleToolsForFirst();
      this.fetchDefaultPossibleToolsForSecond();
    },

    fetchDefaultPossibleToolsForFirst() {
      this.currentPossibleToolsForFirst = Object.values(ToolType).map(
        (tool) => tool.name
      );
    },

    fetchDefaultPossibleToolsForSecond() {
      this.currentPossibleToolsForSecond = Object.values(ToolType).map(
        (tool) => tool.name
      );
    },

    changeToolsForSecond(chosenTool) {
      this.fetchDefaultPossibleToolsForSecond();
      this.currentPossibleToolsForSecond =
        this.currentPossibleToolsForSecond.filter((tool) => {
          return tool !== chosenTool || tool !== ToolType.TOGGL_TRACK.name;
        });
    },
    changeToolsForFirst(chosenTool) {
      this.fetchDefaultPossibleToolsForFirst();
      this.currentPossibleToolsForFirst =
        this.currentPossibleToolsForFirst.filter((tool) => {
          return tool !== chosenTool || tool !== ToolType.TOGGL_TRACK.name;
        });
    },
    async saveClicked() {
      this.isLoading = true;
      let savedConnectionBox;

      if (
        this.connectionBoxId
          .toString()
          .startsWith(this.$t("connectionBoxIdNew"))
      ) {
        // create new connection box
        savedConnectionBox = await createConnection(this.getObjectToSend());
      } else {
        // update existing connection box
        savedConnectionBox = await updateConnection(this.getObjectToSend());
      }

      if (!savedConnectionBox) {
        this.saveError = true;
      } else {
        if (
          this.connectionBoxId
            .toString()
            .startsWith(this.$t("connectionBoxIdNew"))
        ) {
          this.saveProcessed = true;
        } else {
          this.saveUpdated = true;
        }
        this.fetchNewConnectionBox(savedConnectionBox);
        EventBus.$emit(
          "connection-box-saved",
          this.connectionBoxId,
          savedConnectionBox.userConnectionId
        );
      }
      this.isLoading = false;
    },

    fetchNewConnectionBox(savedConnectionBox) {
      const mappedConnectionBox =
        mapConnectionToConnectionBox(savedConnectionBox);
      const connectionBoxes = this.$store.state.userConnectionBoxes;
      for (let pos = 0; pos < connectionBoxes.length; ++pos) {
        if (connectionBoxes[pos].connectionBoxId === this.connectionBoxId) {
          connectionBoxes[pos].connectionBoxId =
            mappedConnectionBox.connectionBoxId;
          connectionBoxes[pos].databaseId = mappedConnectionBox.databaseId;
          connectionBoxes[pos].isActive = mappedConnectionBox.isActive;
          connectionBoxes[pos].firstTool = mappedConnectionBox.firstTool;
          connectionBoxes[pos].secondTool = mappedConnectionBox.secondTool;
          connectionBoxes[pos].schedule = mappedConnectionBox.schedule;
          break;
        }
      }
      this.$store.dispatch("changeUserConnectionBoxes", connectionBoxes);
    },

    getObjectToSend() {
      const connectionBox = this.getCurrentConnectionBox();
      const projectPairs = [];
      connectionBox.projectPairs.forEach((pp) => {
        projectPairs.push({
          idFirstService: pp.firstProject.id,
          idSecondService: pp.secondProject.id,
        });
      });
      return {
        databaseId: connectionBox.databaseId,
        userConnectionId: connectionBox.userConnectionId,
        configSyncJobDefinition: {
          everyHour: connectionBox.schedule.configObjects.everyHour,
          selectionOfDays: connectionBox.schedule.configObjects.selectionOfDays,
          syncTime: connectionBox.schedule.configObjects.syncTime,
        },
        timeEntrySyncJobDefinition: {
          everyHour: connectionBox.schedule.timeEntries.everyHour,
          selectionOfDays: connectionBox.schedule.timeEntries.selectionOfDays,
          syncTime: connectionBox.schedule.timeEntries.syncTime,
        },
        firstTool: connectionBox.firstTool,
        secondTool: connectionBox.secondTool,
        projectMappings: projectPairs,
      };
    },

    // Connection box methods
    getCurrentConnectionBox() {
      let connectionBox = null;
      for (let i = 0; i < this.$store.state.userConnectionBoxes.length; ++i) {
        if (
          this.connectionBoxId ===
          this.$store.state.userConnectionBoxes[i].connectionBoxId
        ) {
          connectionBox = this.$store.state.userConnectionBoxes[i];
          break;
        }
      }
      return connectionBox;
    },
  },
  mounted() {
    EventBus.$on("configuration-box-open", (calledId) => {
      if (calledId === this.connectionBoxId) {
        this.panel = [0];
      }
    });
  },
  props: ["connectionBoxId", "connectionBox"],
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
